/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useMemo } from 'react';
import Skeleton from 'src/styles/skeleton';
import { Currency } from 'src/components/Currency';
import { ProgressBar } from 'src/components/ProgressBar';
import { ProposalSplitType } from 'src/enums';
import { getMonth, getYear } from 'src/utils/dates';
import { DataServerDTO, GroupsDTO } from '../../utils/interfaces';
import { getAmoutColor } from '../../utils/formatText';

interface SplittedAmountProps {
  dataContract?: DataServerDTO;
  proposalSplitType?: ProposalSplitType;
}

export const SplittedAmount: React.FC<SplittedAmountProps> = ({
  dataContract,
  proposalSplitType,
}) => {
  const [date, setDate] = useState('');
  const [groups, setGroups] = useState<GroupsDTO[]>([]);

  const memoizedProgressBar = useMemo(() => {
    if (
      dataContract?.total_value != null &&
      groups[0]?.splitted_amount != null
    ) {
      return (
        <ProgressBar
          value={parseFloat(groups[0]?.splitted_amount)}
          max={parseFloat(dataContract.total_value)}
        />
      );
    }
    return <></>;
  }, [dataContract, groups]);

  useEffect(() => {
    if (dataContract?.included?.groups.length) {
      dataContract.included.groups.sort((a, b) => {
        return (
          new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
        );
      });
      setGroups(dataContract.included.groups || []);
      setDate(dataContract.included.groups[0].start_date || '');
    }
  }, [dataContract]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '715px',
      }}
    >
      <div>
        <h4>Valor Pago</h4>
        {proposalSplitType === ProposalSplitType.monthly ? (
          <span>{date ? `${getMonth(date)}/${getYear(date)}` : <></>}</span>
        ) : (
          <></>
        )}
      </div>
      <div>
        {dataContract ? (
          memoizedProgressBar
        ) : (
          <Skeleton
            style={{
              width: 106,
              height: 30,
              borderRadius: 4,
              marginBottom: 20,
            }}
          />
        )}
      </div>
      <div>
        {dataContract ? (
          <label style={{ marginBottom: 0 }}>
            <p
              style={{
                color: getAmoutColor(
                  dataContract?.included?.groups?.[0].status,
                ),
              }}
            >
              <Currency
                value={dataContract.included?.groups?.[0].splitted_amount}
              />
            </p>
            {dataContract.total_value != null ? (
              <span>
                &nbsp;/&nbsp;
                <Currency value={dataContract.total_value} />
              </span>
            ) : (
              <></>
            )}
          </label>
        ) : (
          <Skeleton
            style={{
              width: 106,
              height: 30,
              borderRadius: 4,
              marginBottom: 20,
            }}
          />
        )}
      </div>
    </div>
  );
};
